body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: #000000;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
color: #000000;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: #000000;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h6 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 15px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 22px;

@media #{$large-up} {
font-size: 25px;

}
}
.me-Quote .quote-author {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 22px;

@media #{$large-up} {
font-size: 25px;

}
}
ol,ul {
color: #000000;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 34px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-4152);}
/* Black 70:4 */
.MES4 {
background-color: var(--clr-4151);
color: var(--clr-4152);
padding: 10px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 30px 27px;

}
 }
.MES4 {
background-color: var(--clr-4151);
color: var(--clr-4152);
padding: 10px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 30px 27px;

}
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
h1.MEC4 { font-size: 20px; }
h1.MEC4 { @media #{$medium-up} { font-size: 30px; }; }
h2.MEC4 { font-size: 20px; }
h2.MEC4 { @media #{$medium-up} { font-size: 30px; }; }
h3.MEC4 { font-size: 16px; }
h3.MEC4 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC4 { font-size: 16px; }
h4.MEC4 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC4 { font-size: 14.4px; }
h5.MEC4 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 12.8px; }
h6.MEC4 { @media #{$medium-up} { font-size: 18px; }; }
 }
cite.MEC4{
color: var(--clr-4152);
}
/* White25:5 */
.MES5 {
background-color: var(--clr-4153);
color: var(--clr-4152);
padding: 30px 27px;

 }
.MES5 {
background-color: var(--clr-4153);
color: var(--clr-4152);
padding: 30px 27px;

h1.MEC5 { font-size: 10px; }
h1.MEC5 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC5 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC5 { font-size: 10px; }
h2.MEC5 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC5 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC5 { font-size: 8px; }
h3.MEC5 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC5 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC5 { font-size: 8px; }
h4.MEC5 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC5 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC5 { font-size: 7.2px; }
h5.MEC5 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC5 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC5 { font-size: 6.4px; }
h6.MEC5 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC5 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC5{
color: var(--clr-4152);
}
/* Cherry:12 */
.MES12 {
background-color: var(--clr-4152);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 15px 10px;

}
 }
/* purple:13 */
.MES13 {
background-color: var(--clr-4154);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 15px 10px;

}
 }
/* dark green:14 */
.MES14 {
background-color: var(--clr-4155);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 15px 10px;

}
 }
/* spring green:15 */
.MES15 {
background-color: var(--clr-4156);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 15px 10px;

}
 }
/* grey:16 */
.MES16 {
background-color: var(--clr-4157);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 15px 10px;

}
 }
/* home panel:17 */
.MES17 {
background-color: transparent;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pooletranslation.com.au/img/1275/40');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #000000;
@media #{$medium-up} {
padding: 0;

}
 }
.MES17 {
background-color: transparent;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pooletranslation.com.au/img/1275/40');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #000000;
@media #{$medium-up} {
padding: 0;

}
h1.MEC17 { font-size: 10px; }
h1.MEC17 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC17 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC17 { font-size: 10px; }
h2.MEC17 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC17 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC17 { font-size: 8px; }
h3.MEC17 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC17 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC17 { font-size: 8px; }
h4.MEC17 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC17 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC17 { font-size: 7.2px; }
h5.MEC17 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC17 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC17 { font-size: 6.4px; }
h6.MEC17 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC17 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC17{
color: #000000;
}
/* Header:18 */
.MES18 {
background-color: #ffffff;
color: var(--clr-4152);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 17.6px;
};
border-width: 0 0 10px 0;
border-style: solid;
border-color: var(--clr-4152);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES18 {
background-color: #ffffff;
color: var(--clr-4152);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 17.6px;
};
border-width: 0 0 10px 0;
border-style: solid;
border-color: var(--clr-4152);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
a.MEC18 { color: var(--clr-4152);
 }
cite.MEC18{
color: var(--clr-4152);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 17.6px;
};
}
/* Lite grey e2e2e2:19 */
.MES19 {
background-color: var(--clr-4155);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 16px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px 40px;

}
@media #{$large-up} {
padding: 40px 50px;

}
 }
.MES19 {
background-color: var(--clr-4155);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 16px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px 40px;

}
@media #{$large-up} {
padding: 40px 50px;

}
 }
cite.MEC19{
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 16px;
};
}
/* transparent black :20 */
.MES20 {
background-color: var(--clr-4158);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

 }
/* transparent white:21 */
.MES21 {
background-color: var(--clr-4159);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px 0;

 }
/* White:24 */
.MES24 {
background-color: #ffffff;
color: #000000;
@media #{$medium-up} {
padding: 0;

}
 }
.MES24 {
background-color: #ffffff;
color: #000000;
@media #{$medium-up} {
padding: 0;

}
h1.MEC24 { font-size: 10px; }
h1.MEC24 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC24 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC24 { font-size: 10px; }
h2.MEC24 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC24 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC24 { font-size: 8px; }
h3.MEC24 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC24 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC24 { font-size: 8px; }
h4.MEC24 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC24 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC24 { font-size: 7.2px; }
h5.MEC24 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC24 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC24 { font-size: 6.4px; }
h6.MEC24 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC24 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC24{
color: #000000;
}
/* New Style:25 */
.MES25 {
background-color: var(--clr-4160);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pooletranslation.com.au/img/33/23');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 10px;

 }
.MES25 {
background-color: var(--clr-4160);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pooletranslation.com.au/img/33/23');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 10px;

h1.MEC25 { font-size: 10px; }
h1.MEC25 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC25 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC25 { font-size: 10px; }
h2.MEC25 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC25 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC25 { font-size: 8px; }
h3.MEC25 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC25 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC25 { font-size: 8px; }
h4.MEC25 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC25 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC25 { font-size: 7.2px; }
h5.MEC25 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC25 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC25 { font-size: 6.4px; }
h6.MEC25 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC25 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC25{
color: #ffffff;
}
/* transparent:26 */
.MES26 {
background-color: transparent;
color: #000000;
padding: 10px 20px;

@media #{$medium-up} {
padding: 20px 40px;

}
@media #{$large-up} {
padding: 20px 50px;

}
 }
.MES26 {
background-color: transparent;
color: #000000;
padding: 10px 20px;

@media #{$medium-up} {
padding: 20px 40px;

}
@media #{$large-up} {
padding: 20px 50px;

}
 }
cite.MEC26{
color: #000000;
}
/* Cherry panel:27 */
.MES27 {
background-color: var(--clr-4152);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
 }
.MES27 {
background-color: var(--clr-4152);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: #ffffff;
 }
h1.MEC27 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC27 { @media #{$large-up} { font-size: 36px; }; }
h3.MEC27 { @media #{$large-up} { font-size: 27px; }; }
h4.MEC27 { @media #{$large-up} { font-size: 22.5px; }; }
h5.MEC27 { @media #{$large-up} { font-size: 22.5px; }; }
h6.MEC27 { @media #{$large-up} { font-size: 16.2px; }; }
 }
cite.MEC27{
color: #ffffff;
}
/* Pink panel:28 */
.MES28 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
 }
.MES28 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
h1.MEC28 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC28 { @media #{$large-up} { font-size: 36px; }; }
h3.MEC28 { @media #{$large-up} { font-size: 27px; }; }
h4.MEC28 { @media #{$large-up} { font-size: 22.5px; }; }
h5.MEC28 { @media #{$large-up} { font-size: 22.5px; }; }
h6.MEC28 { @media #{$large-up} { font-size: 16.2px; }; }
 }
cite.MEC28{
color: #ffffff;
}
/* grey panel:29 */
.MES29 {
background-color: var(--clr-4162);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
 }
.MES29 {
background-color: var(--clr-4162);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 30px 27px;

}
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
h1.MEC29 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC29 { @media #{$large-up} { font-size: 36px; }; }
h3.MEC29 { @media #{$large-up} { font-size: 27px; }; }
h4.MEC29 { @media #{$large-up} { font-size: 22.5px; }; }
h5.MEC29 { @media #{$large-up} { font-size: 22.5px; }; }
h6.MEC29 { @media #{$large-up} { font-size: 16.2px; }; }
 }
cite.MEC29{
color: #ffffff;
}
/* DarkGrey:30 */
.MES30 {
background-color: var(--clr-4163);
color: #ffffff;
padding: 10px;

 }
.MES30 {
background-color: var(--clr-4163);
color: #ffffff;
padding: 10px;

h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
h1.MEC30 { font-size: 10px; }
h1.MEC30 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC30 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC30 { font-size: 10px; }
h2.MEC30 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC30 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC30 { font-size: 8px; }
h3.MEC30 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC30 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC30 { font-size: 8px; }
h4.MEC30 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC30 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC30 { font-size: 7.2px; }
h5.MEC30 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC30 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC30 { font-size: 6.4px; }
h6.MEC30 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC30 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC30 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC30{
color: #ffffff;
}
/* Top Border:31 */
.MES31 {
background-color: var(--clr-4164);
color: #000000;
@media #{$medium-up} {
padding: 0;

}
 }
.MES31 {
background-color: var(--clr-4164);
color: #000000;
@media #{$medium-up} {
padding: 0;

}
h1.MEC31 { font-size: 10px; }
h1.MEC31 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC31 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC31 { font-size: 10px; }
h2.MEC31 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC31 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC31 { font-size: 8px; }
h3.MEC31 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC31 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC31 { font-size: 8px; }
h4.MEC31 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC31 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC31 { font-size: 7.2px; }
h5.MEC31 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC31 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC31 { font-size: 6.4px; }
h6.MEC31 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC31 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC31{
color: #000000;
}
/* New Style:32 */
.MES32 {
background-color: var(--clr-4165);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
 }
.MES32 {
background-color: var(--clr-4165);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
h1.MEC32 { font-size: 10px; }
h1.MEC32 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC32 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC32 { font-size: 10px; }
h2.MEC32 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC32 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC32 { font-size: 8px; }
h3.MEC32 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC32 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC32 { font-size: 8px; }
h4.MEC32 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC32 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC32 { font-size: 7.2px; }
h5.MEC32 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC32 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC32 { font-size: 6.4px; }
h6.MEC32 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC32 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC32{
color: #ffffff;
}
/* Mid grey:33 */
.MES33 {
background-color: var(--clr-4166);
color: #000000;
padding: 10px;

 }
.MES33 {
background-color: var(--clr-4166);
color: #000000;
padding: 10px;

h1.MEC33 { font-size: 10px; }
h1.MEC33 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC33 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC33 { font-size: 10px; }
h2.MEC33 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC33 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC33 { font-size: 8px; }
h3.MEC33 { @media #{$medium-up} { font-size: 12px; }; }
h3.MEC33 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC33 { font-size: 8px; }
h4.MEC33 { @media #{$medium-up} { font-size: 12px; }; }
h4.MEC33 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC33 { font-size: 7.2px; }
h5.MEC33 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC33 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC33 { font-size: 6.4px; }
h6.MEC33 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC33 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC33{
color: #000000;
}
/* Lite grey f2f2f2:34 */
.MES34 {
background-color: var(--clr-4167);
color: #000000;
padding: 15px;

@media #{$medium-up} {
padding: 30px 40px;

}
@media #{$large-up} {
padding: 40px 50px;

}
 }
.MES34 {
background-color: var(--clr-4167);
color: #000000;
padding: 15px;

@media #{$medium-up} {
padding: 30px 40px;

}
@media #{$large-up} {
padding: 40px 50px;

}
h1.MEC34 { font-size: 20px; }
h1.MEC34 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC34 { font-size: 20px; }
h2.MEC34 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC34 { font-size: 16px; }
h3.MEC34 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC34 { font-size: 16px; }
h4.MEC34 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC34 { font-size: 14.4px; }
h5.MEC34 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC34 { font-size: 12.8px; }
h6.MEC34 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC34{
color: #000000;
}
/* home block panel:35 */
.MES35 {
background-color: #ffffff;
color: #000000;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

@media #{$medium-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4167);
 }
.MES35 {
background-color: #ffffff;
color: #000000;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

@media #{$medium-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4167);
h1.MEC35 { font-size: 20px; }
h1.MEC35 { @media #{$medium-up} { font-size: 27px; }; }
h1.MEC35 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC35 { font-size: 20px; }
h2.MEC35 { @media #{$medium-up} { font-size: 27px; }; }
h2.MEC35 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC35 { font-size: 16px; }
h3.MEC35 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC35 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC35 { font-size: 16px; }
h4.MEC35 { @media #{$medium-up} { font-size: 18px; }; }
h4.MEC35 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC35 { font-size: 14.4px; }
h5.MEC35 { @media #{$medium-up} { font-size: 18px; }; }
h5.MEC35 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC35 { font-size: 12.8px; }
h6.MEC35 { @media #{$medium-up} { font-size: 16.2px; }; }
h6.MEC35 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC35{
color: #000000;
}
/* Form background:36 */
.MES36 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES36 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 20px 40px;

}
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
cite.MEC36{
color: #ffffff;
}
/* default:37 */
.MES37 {
background-color: #ffffff;
color: #000000;
padding: 10px;

 }
.MES37 {
background-color: #ffffff;
color: #000000;
padding: 10px;

h1.MEC37 { font-size: 15px; }
h1.MEC37 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC37 { font-size: 15px; }
h2.MEC37 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC37 { font-size: 12px; }
h3.MEC37 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC37 { font-size: 12px; }
h4.MEC37 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC37 { font-size: 10.8px; }
h5.MEC37 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC37 { font-size: 9.6px; }
h6.MEC37 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC37{
color: #000000;
}
/* FAQ Panel:38 */
.MES38 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 10px 15px;

}
@media #{$large-up} {
padding: 30px 27px;

}
 }
.MES38 {
background-color: var(--clr-4161);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 10px 15px;

}
@media #{$large-up} {
padding: 30px 27px;

}
h1.MEC38 { font-size: 15px; }
h1.MEC38 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC38 { font-size: 15px; }
h2.MEC38 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC38 { font-size: 12px; }
h3.MEC38 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC38 { font-size: 12px; }
h4.MEC38 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC38 { font-size: 10.8px; }
h5.MEC38 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC38 { font-size: 9.6px; }
h6.MEC38 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC38 { color: var(--clr-4167);
&:hover { color: #ffffff;}
 }
cite.MEC38{
color: #ffffff;
}
/* Primary:40 */
.MES40 {
background-color: var(--clr-4152);
color: #ffffff;
 }
/* Primary:41 */
.MES41 {
background-color: var(--clr-4152);
color: #ffffff;
 }
.MES41 {
background-color: var(--clr-4152);
color: #ffffff;
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: #ffffff;
 }
 }
cite.MEC41{
color: #ffffff;
}
/* Secondary:42 */
.MES42 {
background-color: var(--clr-4161);
color: #ffffff;
 }
/* Secondary:43 */
.MES43 {
background-color: var(--clr-4161);
color: #ffffff;
 }
.MES43 {
background-color: var(--clr-4161);
color: #ffffff;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
cite.MEC43{
color: #ffffff;
}
/* Main Menu:44 */
nav.me-Menu.MES44 {
.menu-item.MEC44{ &:hover {background-color: var(--clr-4166);}
}
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: #000000;
text-transform: uppercase;
}
 &:hover > a.MEC44{color: #000000;
}
 }
&.horizontal > .menu-item.MEC44 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC44 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC44 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC44 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 33px 10px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-4151-flat);}}
&.vertical .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-4151-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC44{background-color: var(--clr-4151); &:hover {background-color: 6;}
}
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: #ffffff;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC44{color: 31;
}
 }

}
}
 }
/* Slider:45 */
.MES45 {
& .slider-arrow {color: var(--clr-4166);
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: #ffffff;
} }
/* Home Carousel:46 */
.MES46 {
& .slider-arrow {background-color: 13;
color: #ffffff;
font-size: 30px;
@media #{$medium-up} {
font-size: 30px;
};
@media #{$large-up} {
font-size: 30px;
};
}& .slider-arrow:hover{background-color: 3;
color: #ffffff;
} }
/* FAQ:47 */
details.MES47 {
& > summary{background-color: var(--clr-4161);
}
color: #000000;
& > article { color: #000000;
 }
& > summary{padding: 20px;}

& > article{padding: 20px;}

& > summary{border-width: 0 0 1px 0;
border-style:0 0 0 solid;
border-color: transparent transparent #ffffff transparent;
}& > summary {& > div  > i{color: #ffffff;
font-size: 16px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 16px;
};
}}
& > summary { color: #ffffff;
font-size:22px;
@media #{$large-up} {
font-size:30px;
}
 }
& > article a { color: var(--clr-4152);
&:hover { color: var(--clr-4161); }
 }
& > article li { color: var(--clr-4162);
 }
 }
/* Form Button:49 */
.MES49 {
background-color: var(--clr-4158);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 30px;

}
@media #{$large-up} {
padding: 10px 50px;

}
 }
/* Shade 1:50 */
.MES50 {
background-color: var(--clr-4155);
color: #000000;
 }
/* Shade 1:51 */
.MES51 {
background-color: var(--clr-4155);
color: #000000;
 }
.MES51 {
background-color: var(--clr-4155);
color: #000000;
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: #000000;
 }
 }
cite.MEC51{
color: #000000;
}
/* Light:52 */
.MES52 {
background-color: #ffffff;
color: #000000;
 }
/* Light:53 */
.MES53 {
background-color: #ffffff;
color: #000000;
 }
.MES53 {
background-color: #ffffff;
color: #000000;
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: #000000;
 }
 }
cite.MEC53{
color: #000000;
}
/* :54 */
.MES54 {
& > hr {border-width: 10px 0 0 0;}
& > hr {border-color: var(--clr-4152) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Responsive Phone Panel:55 */
.MES55 {
background-color: var(--clr-4163);
color: #ffffff;
padding: 10px;

 }
.MES55 {
background-color: var(--clr-4163);
color: #ffffff;
padding: 10px;

h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: #ffffff;
 }
 }
a.MEC55 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC55{
color: #ffffff;
}
/* Home Carousel Title:56 */
.MES56 {
background-color: var(--clr-4167);
padding: 10px;

 }
.MES56 {
background-color: var(--clr-4167);
padding: 10px;

 }
/* Filter Panel:57 */
.MES57 {
background-color: var(--clr-4152);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$large-up} {
padding: 20px 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4152);
 }
.MES57 {
background-color: var(--clr-4152);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$large-up} {
padding: 20px 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4152);
h1.MEC57, h2.MEC57, h3.MEC57, h4.MEC57, h5.MEC57, h6.MEC57 { color: #ffffff;
 }
h1.MEC57 { font-size: 22.5px; }
h1.MEC57 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC57 { font-size: 22.5px; }
h2.MEC57 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC57 { font-size: 18px; }
h3.MEC57 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC57 { font-size: 18px; }
h4.MEC57 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC57 { font-size: 16.2px; }
h5.MEC57 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC57 { font-size: 14.4px; }
h6.MEC57 { @media #{$large-up} { font-size: 18px; }; }
 }
/* News Menu:58 */
.MES58 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-4152) transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* Black Button:59 */
.MES59 {
background-color: #000000;
&:hover {background-color: var(--clr-4151);}
color: #ffffff;
 }
/* :61 */
nav.responsive-menu {
.menu-item.MEC61{background-color: var(--clr-4161);}
& .menu-item.MEC61, & .menu-item.MEC61 > div.menu-item-wrap{ & > a.MEC61, & > i{color: #ffffff;
text-align: center;
}
  }
& .menu-item.MEC61 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* Homepage News Panel:62 */
.MES62 {
background-color: var(--clr-4152);
color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 5px;
border-style: solid;
border-color: var(--clr-4152);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES62 {
background-color: var(--clr-4152);
color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 5px;
border-style: solid;
border-color: var(--clr-4152);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC62, h2.MEC62, h3.MEC62, h4.MEC62, h5.MEC62, h6.MEC62 { color: #ffffff;
 }
 }
.MEC62 li {color: #ffffff;}
cite.MEC62{
color: #ffffff;
}
/* Rollover action:63 */
.MES63 {
background-color: transparent;
&:hover, &.hover { background-color: #d1102d84;}
 }
.MES63 {
background-color: transparent;
&:hover, &.hover { background-color: #d1102d84;}
h1.MEC63, h2.MEC63, h3.MEC63, h4.MEC63, h5.MEC63, h6.MEC63 { color: transparent;
 }
&:hover { h1.MEC63, h2.MEC63, h3.MEC63, h4.MEC63, h5.MEC63, h6.MEC63 { color: #ffffff;} }
 }
/* Page Accordion:64 */
details.MES64 {
 }
/* White background:65 */
.MES65 {
background-color: #ffffff;
 }
.MES65 {
background-color: #ffffff;
 }
